import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AppContainer } from '@ghm/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider>
        <AppContainer />
      </SnackbarProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

